<template>
  <div class="mt-4 container-fluid">
    <div class="d-flex justify-content-between mb-4">
      <h3>Commissions - Payouts</h3>
    </div>
    <b-card no-body>
      <div class="d-flex">
        <nav class="payout-nav">
          <router-link v-for="menu in menus" :key="menu.route" :to="`/commissions${menu.route}`">
            <div :class="['item mt-2', {'item-selected': isActive(menu.route)}]">
              {{ menu.title }}
            </div>
          </router-link>
        </nav>
        <b-container class="mt-4 overflow-auto" fluid>
          <slot/>
        </b-container>
      </div>
    </b-card>
  </div>
</template>

<script>
import translations from '@/translations';

export default {
  name: 'PayoutsLayout',
  data() {
    return {
      translations: translations.commissions,
      menus: [
        {
          title: translations.commissions.statements.nav_title,
          route: '/statements',
        },
        {
          title: translations.commissions.transactions.nav_title,
          route: '/transactions',
        },
      ],
    };
  },
  methods: {
    isActive(route) {
      return this.$route.path.includes(route);
    },
  },
};
</script>
<style scoped>
.payout-content {
  width: calc(100% - 235px);
}
.payout-nav {
  display: flex;
  flex-direction: column;
  padding: 0.75rem 1.25rem;
  background-color: #e7eaf0;

  .item {
    padding: 0.5rem 1rem;
    color: #007bff;
    text-decoration: none;
  }

  a:hover {
    text-decoration: none;
  }

  .item-selected {
    color: #fff;
    background-color: #007bff;
    border-radius: 0.25rem;
  }
}
</style>
